// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';

// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LinesComponent,
  GeoComponent,
  EffectScatterComponent,
  ScatterComponent,
  LegendComponent,
  VisualMapComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components';

// 引入柱状图图表，图表后缀都为 Chart
import {
  BarChart,
  MapChart,
  LinesChart,
  EffectScatterChart,
  ScatterChart,
  PieChart,
  LineChart,
  RadarChart,
  GaugeChart,
  PictorialBarChart
} from 'echarts/charts';

// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
  SVGRenderer
} from 'echarts/renderers';

// 注册必须的组件
echarts.use(
  [   
    TitleComponent, 
    TooltipComponent, 
    DataZoomComponent, 
    GridComponent, 
    GeoComponent, 
    SVGRenderer,
    EffectScatterChart, 
    ScatterChart, 
    ToolboxComponent,
    LegendComponent, 
    VisualMapComponent, 
    PictorialBarChart,
    RadarChart,
    GaugeChart,
    PieChart, 
    LineChart,
    BarChart, 
    MapChart, 
    LinesChart, 
  ]
);

export default echarts
